<template>
  <UCard
    :ui="{
      root: 'overflow-hidden w-full',
      body: 'p-0 sm:p-0 relative w-full h-full aspect-[16/9] isolate',
    }"
  >
    <ScriptYouTubePlayer
      ref="video"
      v-posthog-capture="'video_player_clicked'"
      :video-id="videoId"
      :width="1280"
      :height="720"
      above-the-fold
    >
      <template #placeholder>
        <NuxtImg
          sizes="xs:100svw sm:100svw md:100svw lg:40svw xl:40svw xxl:30svw"
          class="absolute inset-0 h-full w-full object-cover"
          :src="`https://i.ytimg.com/vi_webp/${videoId}/hqdefault.webp`"
          alt="YouTube Video Thumbnail"
        />
      </template>
      <template #awaitingLoad>
        <div
          class="absolute top-1/2 left-1/2 h-[48px] w-[68px] -translate-x-1/2 -translate-y-1/2 transform"
        >
          <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
            <path
              d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
              fill="#f00"
            />
            <path d="M 45,24 27,14 27,34" fill="#fff" />
          </svg>
        </div>
      </template>
    </ScriptYouTubePlayer>
  </UCard>
</template>

<script setup lang="ts">
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
});

// Function to extract YouTube video ID from a URL
const extractYouTubeId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

// Compute the video ID from the prop's URL
const videoId = computed(() => extractYouTubeId(props.blok.youtube_url.url));
</script>
